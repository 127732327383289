body
  background-color: rgb(255, 254, 240)

.app  
  max-width: 1024px
  flex-grow: 1
  margin-left: auto
  margin-right: auto
  margin-bottom: 1em
  padding-left: 1em
  padding-right: 1em
