.home
  font-size: 64px
  display: flex
  flex-direction: column
  align-items: center

  div:first-of-type
    margin-bottom: 0.25em

  img:first-of-type
    max-width: 500px