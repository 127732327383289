.otaku-navbar
  column-count: 1
  column-gap: 1em
  margin-left: 1em
  margin-right: 1em

  @media only screen and (min-width: 1024px)
    column-count: 4

  @media only screen and (max-width: 1023px) and (min-width: 768px)
    column-count: 3

  @media only screen and (max-width: 767px) and (min-width: 540px)
    column-count: 2


.otaku-item
  display: inline-block
  margin: 0 0 1em
  width: 100%
  max-width: 250px
  margin-left: 1em
  margin-right: 1em

  &-image
    width: 100%
    max-height: 300px

  &:hover
    background-color: lightgreen
