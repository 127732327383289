
.japanese
  &-translation
    font-size: 24px
    text-align: center

    &-title
      width: 100%
      display: flex
      margin-bottom: 0.5em

    &-content
      width: 100%
      display: flex

    &-divider
      border: solid black 3px
      margin: 0em 1em
    
  &-original
    width: 50%

    &-word
      display: inline-block
      background-color: #eaeaea
      padding: 0.1em
      margin-right: 0.1em
      cursor: pointer

      &:hover
        background-color: #babafa

      &--active
        background-color: #cacafa

  &-meaning
    width: 50%
    font-size: 20px
    line-height: 1.5

