.breadcrumbs
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
  display: flex
  margin-bottom: 1em
  margin-left: auto
  margin-right: auto
  padding-left: 1em
  padding-right: 1em
  max-width: 1024px

  div
    margin-right: 0.25em

  &-active
    color: #0210d6
    
  &-previous
    color: #6a88ff
    opacity: 0.7