.navbar
  display: flex
  margin-bottom: 0.5em
  justify-content: center
  flex-wrap: wrap
  padding: 0.5em
  background-color: #8bcbff
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif

  &-link
    display: flex
    align-items: center
    margin-left: 0.5em
    margin-right: 0.5em
    color: black
    word-break: keep-all
    border-bottom: 3px solid
    border-color: transparent

    .Icon
      margin-right: 0.15em

    &--first
      margin-left: calc(50% - 160px)

    &-dome
      margin-left: auto

    &:hover
      border-color: blue

  & i
    margin-right: 0.1em

  &-rem
    width: 2em
    height: 2em
    
  .link:hover
    .navbar-rem
      transform: scaleX(-1)

