.sheets
  font-size: 17px
  font-family: Georgia, serif
  a
    text-decoration: none
  a:hover
    text-decoration: underline

.dir .dir
  margin-left: 2em


.dir-content
  margin-left: 2em


.file
  background-color: rgb(240, 233, 213)
